.ctaSection {
  margin-top: 6em; /* 30px converti en em */
  margin-bottom: 6em;
  padding: 1.75em 1.25em; /* 60px 20px converti en em */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.ctaSection.visible {
  opacity: 1;
  transform: translateY(0);
}

.cta-investSection {
  margin-top: 6em; /* 30px converti en em */
  margin-bottom: 6em;
  padding: 1.75em 1.25em; /* 60px 20px converti en em */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.cta-investSection.visible {
  opacity: 1;
  transform: translateY(0);
}

.ctaContainer {
  max-width: 50em; /* 800px converti en em */
  margin: 0 auto;
}

.ctaTitle {
  font-family: 'Raleway', sans-serif;
  font-size: 2.375em; /* 38px converti en em */
  font-weight: bold;
  margin-bottom: 1.25em; /* 20px converti en em */
  color: #243EFF;
  transition: color 0.3s ease;
}

.ctaSubtitle {
  font-family: 'Raleway', sans-serif;
  font-size: 1.0625em; /* 17px converti en em */
  margin-bottom: 1.875em; /* 30px converti en em */
  color: black;
  transition: color 0.3s ease;
}

.primaryButton {
  background-color: #243EFF;
  color: white;
  padding: 0.9375em 1.875em; /* 15px 30px converti en em */
  border: none;
  border-radius: 3.125em; /* 50px converti en em */
  font-size: 1.2em;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.primaryButton:hover {
  background-color: #1a2ecc;
  transform: scale(1.05);
}

.ctaImageContainer {
  margin-left: 5vw;
  margin-right: 15vw;
}

.ctaImage {
  width: 170%; /* Assure que l'image prend toute la largeur du conteneur */
  height: auto;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.2); /* 4px 10px converti en em */
}

.cta-investContainer {
  max-width: 50em; /* 800px converti en em */
  margin: 0 auto;
}

.cta-investTitle {
  font-family: 'Raleway', sans-serif;
  font-size: 2.375em; /* 38px converti en em */
  font-weight: bold;
  margin-bottom: 1.25em; /* 20px converti en em */
  color: #243EFF;
  transition: color 0.3s ease;
}

.cta-inestSubtitle {
  font-family: 'Raleway', sans-serif;
  font-size: 1.0625em; /* 17px converti en em */
  margin-bottom: 1.875em; /* 30px converti en em */
  color: black;
  transition: color 0.3s ease;
}

.cta-investImageContainer {
  margin-left: 5vw;
  margin-right: 15vw;
}

.cta-investImage {
  width: 170%; /* Assure que l'image prend toute la largeur du conteneur */
  height: auto;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.2); /* 4px 10px converti en em */
}

/* Media queries pour rendre la section responsive */
@media (max-width: 768px) {
  .ctaSection {
    flex-direction: column;
    text-align: center;
    margin-top: 0;
  }

  .ctaTitle {
    font-size: 1.75em; /* 28px converti en em */
  }

  .ctaSubtitle {
    font-size: 0.9375em; /* 15px converti en em */
  }

  .primaryButton {
    padding: 0.625em 1.25em; /* 10px 20px converti en em */
    font-size: 1em;
  }

  .ctaImageContainer {
    margin-top: 2.25em; /* 20px converti en em */
    display: flex;
    align-items: center;
    max-width: 100%; /* Assure que le conteneur de l'image prend toute la largeur disponible */
  }

  .ctaImage {
    margin-left: 0; /* Supprime la marge gauche pour les petits écrans */
    width: 120%;
  }

  .cta-investSection {
    flex-direction: column;
    text-align: center;
  }

  .ctaTitle {
    font-size: 1.75em; /* 28px converti en em */
    margin-top: 0;
  }

  .ctaSubtitle {
    font-size: 0.9375em; /* 15px converti en em */
  }

  .primaryButton {
    padding: 0.625em 1.25em; /* 10px 20px converti en em */
    font-size: 1em;
  }

  .cta-investImageContainer {
    margin-top: 2.25em; /* 20px converti en em */
    display: flex;
    align-items: center;
    max-width: 100%; /* Assure que le conteneur de l'image prend toute la largeur disponible */
  }

  .cta-investImage {
    margin-left: 0; /* Supprime la marge gauche pour les petits écrans */
    width: 120%;
  }
}