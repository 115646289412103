/* ArticleTemplate.css */
.article-content a:hover {
  text-decoration: none;
}

/* Styles pour le bloc des articles les plus lus */
.most-read-container {
  max-width: 20em; /* 400px converti en em */
  width: 100%;
  max-height: 31em; /* 380px converti en em */
  margin-top: 2.5em; /* 40px converti en em */
  padding: 1.25em; /* 20px converti en em */
  border-radius: 0.5em; /* 8px converti en em */
  background-color: white;
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.5); /* 4px 12px converti en em */
  transition: box-shadow 0.3s ease;
}

.most-read-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #243EFF;
  margin-bottom: 0.625em; /* 10px converti en em */
  text-align: center;
}

.most-read-list {
  list-style: none;
  padding: 0;
}

.most-read-item {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.most-read-content {
  display: flex;
  align-items: center;
  margin-bottom: 0.625em; /* 10px converti en em */
}

.most-read-image {
  width: 7em;
  height: 5em;
  margin-right: 0.625em; /* 10px converti en em */
  border-radius: 0.25em; /* 4px converti en em */
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.2); /* 4px 12px converti en em */
  transition: transform 0.3s ease; /* Ajoutez une transition pour l'effet de zoom */
  object-fit: cover; /* Assurez-vous que l'image couvre tout le conteneur */
  object-position: center; /* Centrez l'image dans le conteneur */
}

li {
  font-size: inherit;
}

.most-read-image:hover {
  transform: scale(1.1); /* Appliquez un effet de zoom lors du survol */
}

.most-read-link {
  color: black;
  font-size: 1rem;
  font-weight: bold;
}

.most-read-link:hover {
  text-decoration: none;
}

.most-read-item a {
  text-decoration: none; /* Retirer le soulignage des liens */
}

/* Styles pour le bloc des réseaux sociaux */
.social-network {
  max-width: 25em; /* 400px converti en em */
  width: 100%;
  margin: 3.5em auto; /* 40px converti en em */
  padding: 1.25em; /* 20px converti en em */
  border-radius: 0.5em; /* 8px converti en em */
  background-color: white;
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.2); /* 4px 12px converti en em */
  transition: box-shadow 0.3s ease;
  text-align: center; /* Centre le contenu horizontalement */
}

.social-network-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #243EFF;
  margin-bottom: 1em; /* 16px converti en em */
  margin-top: 0;
  text-align: center;
}

.social-network-list {
  list-style: none;
  padding: center;
  display: flex; /* Utilisez Flexbox pour aligner les éléments sur la même ligne */
  justify-content: center; /* Centre les éléments horizontalement */
  gap: 1.25em; /* 20px converti en em */
}

.social-network-item {
  display: flex;
  align-items: center;
}

.social-network-link {
  color: black;
  text-decoration: none;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  border: none; /* Enlever les bordures */
  background: none; /* Enlever le fond */
  cursor: pointer; /* Changer le curseur pour indiquer que c'est cliquable */
}

.social-network-link:hover {
  text-decoration: none;
}

.social-network-logo {
  width: 1.75em; /* 28px converti en em */
  height: 1.75em; /* 28px converti en em */
  margin-right: 0.625em; /* 10px converti en em */
}

.share-options {
  flex-direction: row;
  position: absolute;
  background-color: white;
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.2); /* 4px 12px converti en em */
  border-radius: 0.5em; /* 8px converti en em */
  padding: 0.625em; /* 10px converti en em */
  margin-top: 11em; /* 200px converti en em */
  margin-left: -12em; /* -190px converti en em */
} 

.share-option {
  margin: 0.3125em 0; /* 5px converti en em */
  color: #243EFF;
  text-decoration: none;
  font-size: 1.1rem;
  margin: 0 0.625em; /* 10px converti en em */
}

.share-option:hover {
  text-decoration: none;
}

/* Styles pour le conteneur principal */
.article-template-background {
  background-image: url('/src/assets/Wallpaper.png'); /* Chemin vers ton image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh; /* Assurez-vous que le conteneur couvre toute la hauteur de la fenêtre */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Aligne les éléments en haut */
  padding-top: 3.75em; /* 60px converti en em */
}

.article-template-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 81.25em; /* 1300px converti en em */
  width: 100%;
  padding: 1.25em; /* 20px converti en em */
  gap: 2.5em; /* 40px converti en em */
}

.article-template-container {
  flex: 2;
  max-width: 50em; /* 800px converti en em */
  margin: 2.5em auto; /* 40px converti en em */
  padding: 1.875em; /* 30px converti en em */
  border-radius: 0.5em; /* 8px converti en em */
  background-color: white;
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.5); /* 4px 12px converti en em */
  transition: box-shadow 0.3s ease;
}

.article-template-header {
  text-align: center;
}

.article-template-title {
  font-size: 2.2rem;
  font-weight: bold;
  color: #243EFF;
  margin-bottom: 1em; /* 16px converti en em */
  transition: color 0.3s ease;
}

.article-template-meta {
  font-size: 0.9rem;
  color: #999;
  display: flex;
  justify-content: center;
  margin-bottom: 1.25em; /* 20px converti en em */
}

.article-template-separator {
  margin: 0 0.625em; /* 10px converti en em */
}

.article-template-content h2 {
  font-size: 1.5em; /* Ajustez la taille de la police selon vos besoins */
  color: #243EFF; /* Assurez-vous que la couleur du texte est correcte */
  margin-top: 1.25em; /* 20px converti en em */
  margin-bottom: 1.25em; /* 20px converti en em */
}

.article-template-body {
  text-align: left;
  display: flex;
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center; /* Centre le contenu verticalement */
  flex-direction: column; /* Aligne les éléments en colonne */
}

.article-template-image {
  width: 80%;
  height: auto;
  margin-bottom: 1.25em; /* 20px converti en em */
  border-radius: 0.5em; /* 8px converti en em */
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.2); /* 4px 12px converti en em */
  align-self: center; /* Centre l'image horizontalement */
}

.image-template-credit {
  font-size: 0.8rem;
  color: #777;
  text-align: center;
  margin-bottom: 1.25em; /* 20px converti en em */
}

.article-template-content {
  color: black;
  font-size: 1.2em;
  width: 100%; /* Assurez-vous que le contenu prend toute la largeur disponible */
  line-height: 1.6; /* Espacement entre les lignes */
  padding-bottom: 2.5em; /* 40px converti en em */
  text-align: justify; /* Justifie le texte */
}

.article-template-content p {
  margin: 0.625em 0; /* 10px converti en em */
}

.article-template-content a {
  color: #243EFF; /* Assurez-vous que les liens ont la bonne couleur */
  text-decoration: none; /* Enlevez la décoration des liens */
}

.article-template-content a:hover {
  text-decoration: underline; /* Ajoutez une décoration au survol des liens */
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 1.25em; /* 20px converti en em */
  position: -webkit-sticky; /* Pour Safari */
  position: sticky;
  top: 5em; /* 80px converti en em */
  margin-bottom: 2.5em; /* 40px converti en em */
}

.arrow {
  cursor: pointer;
  font-size: 2rem; /* Augmentez cette valeur pour augmenter la taille de la flèche */
  color: #243EFF;
  margin-left: 0.3125em; /* 5px converti en em */
  text-decoration: none; /* Enlever la décoration du texte */
}

.arrow:hover {
  text-decoration: none; /* Enlever la décoration du texte lors du survol */
}

.navbar__arrow {
  font-size: 0.6875em; /* 11px converti en em */
  text-decoration: none;
}

/* Media Queries pour rendre le site responsive */
@media (max-width: 768px) {
  .article-template-wrapper {
    flex-direction: column;
    align-items: center;
    max-width: 21.875em; /* 350px converti en em */
    padding: 1.25em; /* 20px converti en em */
    margin: 0 1.25em; /* Ajoute des marges de 20px de chaque côté */
  }

  .article-template-container {
    max-width: 21.875em; /* 350px converti en em */
    padding: 1.25em; /* 20px converti en em */
    margin: 0;
  }

  .article-template-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #243EFF;
    margin-bottom: 1em; /* 16px converti en em */
    transition: color 0.3s ease;
  }
  
  .article-template-meta {
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1.25em; /* 20px converti en em */
  }
  
  .article-template-separator {
    margin: 0 0.625em; /* 10px converti en em */
  }
  
  .article-template-content h2 {
    font-size: 1.2em; /* Ajustez la taille de la police selon vos besoins */
    margin-top: 1.25em; /* 20px converti en em */
    margin-bottom: 1.25em; /* 20px converti en em */
  }
  
  .article-template-body {
    text-align: left;
    display: flex;
    justify-content: center; /* Centre le contenu horizontalement */
    align-items: center; /* Centre le contenu verticalement */
    flex-direction: column; /* Aligne les éléments en colonne */
  }
  
  .article-template-image {
    width: 100%;
  }
  
  .image-template-credit {
    font-size: 0.6rem;
    color: #777;
    text-align: center;
    margin-bottom: 1.25em; /* 20px converti en em */
  }
  
  .article-template-content {
    color: black;
    font-size: 1em;
    width: 100%; /* Assurez-vous que le contenu prend toute la largeur disponible */
    line-height: 1.6; /* Espacement entre les lignes */
    padding-bottom: 2.5em; /* 40px converti en em */
    text-align: justify; /* Justifie le texte */
  }

  .most-read-container {
    max-width: 90%;
    min-height: 52vh;
    margin-top: 0;
    margin-left: 0;
  }

  .social-network-title {
    margin-top: 0;
  }

  .social-network {
    max-width: 85%;
    margin-top: 1.25em; /* 20px converti en em */
    margin-bottom: 3.75em; /* 60px converti en em */
  }

  .most-read-title {
    font-size: 1.5rem;
    margin-bottom: 0.625em; /* 10px converti en em */
    text-align: center;
    margin-top: 0.625em; /* 10px converti en em */
  }

  .sidebar {
    position: static;
    margin-top: 1.25em; /* 20px converti en em */
    padding: 0.3125em; /* 5px converti en em */
  }

  .share-options {
    flex-direction: row;
    position: absolute;
    background-color: white;
    border-radius: 0.5em; /* 8px converti en em */
    padding: 0.625em; /* 10px converti en em */
    margin-top: 11em; /* 200px converti en em */
    margin-left: -10em; /* -190px converti en em */
    gap: 1.875em; /* 30px converti en em */
  } 
}