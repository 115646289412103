  .académie-hero {
    display: flex;
    flex-direction: column; /* Change la disposition en colonne pour les grands écrans */
    align-items: center;
    justify-content: center;
    padding: 2em 2em; /* 20px 20px converti en em */
    overflow: hidden;
    min-height: 100vh;
  }

  .académie-textContainer {
    max-width: 60em; /* 800px converti en em */
    text-align: center;
    margin: 4em 0 5em 0; /* Ajoute une marge en bas pour espacer du visual */
    color: black;
  }

  .académie-heading {
    font-size: 3.5em; /* 56px converti en em */
    font-weight: bold;
    color: #f5f5f5;
  }

  .académie-paragraph {
    color: #f5f5f5;
    font-size: 1.1875em; /* 19px converti en em */
  }

  .académie-buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.875em; /* 20px converti en em */
  }

  .académie-primaryButton {
    background-color: #243EFF;
    color: white;
    padding: 0.625em 1.25em; /* 10px 20px converti en em */
    border: none;
    border-radius: 3.125em; /* 50px converti en em */
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .académie-primaryButton:hover {
    background-color: #1d2ee7;
    transform: scale(1.05); /* Zoom au survol */
  }
/* Media queries pour rendre la section héroïque responsive */
@media (max-width: 768px) {
  .académie-hero {
    flex-direction: column; /* Change la disposition en colonne pour les petits écrans */
    margin-top: 6vh; /* 60px converti en em */
    min-height: 45vh;
    margin-bottom: 3.75em; /* 60px converti en em */
    padding: 0;
  }

  .académie-heading {
    font-size: 2.25em; /* 36px converti en em */
    font-weight: bold;
    color: #f5f5f5;
  }

  .académie-textContainer {
    margin: 0; /* Ajoute une marge en bas pour espacer du visual */
  }
  
  .académie-paragraph {
    color: #f5f5f5;
    font-size: 1em; /* 16px converti en em */
    margin-top: 1.875em; /* 30px converti en em */
    margin-bottom: 1.875em; /* 30px converti en em */
  }

  .académie-iframe {
    margin-top: 5em; /* 80px converti en em */
    width: 75%; /* Ajuste la largeur de la vidéo pour les petits écrans */
    height: auto; /* Ajuste la hauteur de la vidéo pour les petits écrans */
    border-radius: 1.250em; /* 10px converti en em */
  }
}