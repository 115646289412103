.apropos-background {
  background-image: url('/src/assets/Wallpaper.png'); /* Chemin vers ton image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh; /* Assurez-vous que le conteneur couvre toute la hauteur de la fenêtre */
}

.container {
  text-align: center;
  padding: 1.25em; /* 20px converti en em */
}

.apropos-container {
  font-family: Raleway, sans-serif;
  margin: 1.25em auto; /* 20px converti en em */
  color: white;
  min-height: 100vh; /* Assurez-vous que le conteneur couvre toute la hauteur de la fenêtre */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Centre le texte */
  margin-top: 4.7em; /* 60px converti en em */
}

.certification {
  color : black;
  font-size: 0.8em;
}

.about-section {
  font-size: 1.125em; /* 18px converti en em */
  display: flex; /* Utilisez flexbox pour aligner les éléments sur la même ligne */
  flex-direction: row; /* Aligne les éléments horizontalement */
  justify-content: space-around; /* Espace entre les éléments */
  align-items: center; /* Aligne les éléments verticalement */
  flex-wrap: wrap; /* Permet aux éléments de passer à la ligne suivante si nécessaire */
}

h1, h2 {
  font-size: 3.5em; /* 56px converti en em */
  margin-bottom: 0;
  color: white;
  text-align: center; /* Centre le texte */
  font-weight: 700;
  margin-left: 2em;
  margin-right: 2em;
}

.subtitle-section {
  margin-bottom: 3.5em; /* 30px converti en em */
  max-width: 50em;
  text-align: center;
}

.founder-subtitle-section {
  margin-top: 1.5em; /* 40px converti en em */
  margin-bottom: 2.5em; /* 30px converti en em */
  max-width: 50em;
  text-align: center;
  font-size: 1.125em; /* 18px converti en em */
  margin-left: auto; /* Centre le conteneur */
  margin-right: auto; /* Centre le conteneur */
}

.value-images-section {
  display: flex;
  justify-content: space-around;
  gap: 2em; /* 20px converti en em */
  margin-bottom: 2.5em; /* 40px converti en em */
  margin-top: 2.5em; /* 40px converti en em */
  margin-left: 4em;
  margin-right: 4em;
}

.value-card {
  padding: 1.25em; /* 20px converti en em */
  text-align: center;
  max-width: 300px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em; /* 16px converti en em */
}

.text-container {
  background-color: #fff;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5); /* 0 4px 8px converti en em */
  padding: 0.7em;
  opacity: 0;
  transform: translateY(1.25em); /* 20px converti en em */
  transition: opacity 1s ease, transform 1s ease;
}

.text-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.value-image {
  width: 100%;
  height: auto;
  border-radius: 0.5em; /* 8px converti en em */
  transition: transform 0.3s ease-in-out;
}

.value-image:hover {
  transform: scale(1.05); /* Zoom au survol */
}

.image-title {
  font-size: 1.5em;
  color: #243EFF;
  margin-bottom: 0.5em; /* 8px converti en em */
  margin-top: 0;
}

.image-subtitle {
  font-size: 1rem;
  color: #000;
}

.founders-section {
  text-align: center;
  padding: 2em 0;
}

.founders {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
}

.founder {
  background-color: #fff;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5); /* 0 4px 8px converti en em */
  overflow: hidden;
  max-width: 600px;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 1.25em; /* 20px converti en em */
  margin-bottom: 2em; /* 32px converti en em */
  opacity: 0;
  transition: opacity 1s ease-in-out , transform 0.3s ease-in-out;
}

.founder.visible {
  opacity: 1;
}

.founder:hover {
  transform: scale(1.05); /* Zoom au survol */
}

.founder-img-container {
  flex-shrink: 0;
  margin-right: 1.25em; /* 20px converti en em */
}

.founder-img {
  width: 13em; /* 80px converti en em */
  height: 13em; /* 80px converti en em */
  border-radius: 100%;
  object-fit: cover;
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.2); /* 4px 10px converti en em */
}

.founder-info h3 {
  margin: 0;
  font-size: 1.5em;
  color: #243EFF;
}

.founder-info h4 {
  margin: 0.5em 0;
  color: #555;
}

.founder-subtitle {
  font-size: 0.8em;
  margin-bottom: 1em;
  color: #333;
}

.founder-certifications {
  margin-top: 1em; /* 16px converti en em */
}

.founder-certifications h5 {
  margin: 0;
  color: #666;
}

.founder-certifications p {
  color: #333; /* Couleur du texte pour les certifications */
  font-size: 1em;
}

.founder-social-links {
  display: flex;
  gap: 1em;
}

.founder-social-link {
  display: inline-block;
}

.founders-social-icons {
  width: 24px;
  height: 24px;
}

.socialNetwork {
  color: inherit;
}

.founders-social-icons {
  width: 1.75em; /* 28px converti en em */
}

.cta-section {
  background-color: #f5f5f5;
  padding: 1.25em; /* 20px converti en em */
  border-radius: 0.5em; /* 8px converti en em */
  text-align: center;
}

/* Ajustement pour les petits écrans */
@media (max-width: 768px) {

  .apropos-background {
    padding: 0;
  }

  .container {
    margin-left: 7vw;
    margin-right: 7vw;
  }

  .apropos-container {
    margin-top: 6vh; /* 20px converti en em */
  }
  
  .about-section {
    flex-direction: column; /* Aligne les éléments verticalement */
  }

  .value-images-section {
    flex-direction: column;
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .value-card {
    width: 90%;
    margin-bottom: 1.25em; /* 20px converti en em */
  }

  .value-image {
    margin-bottom: -1em;
  }

  .founders-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .founders {
    flex-direction: column;
  }

  .founder {
    flex-direction: column; /* Aligne les éléments verticalement */
    text-align: center;
  }

  .founder-img-container {
    margin-right: 0;
    margin-bottom: 1.25em; /* 20px converti en em */
  }

  .founder-social-links {
    justify-content: center;
  }

  .founder-img {
    border-radius: 100%;
    object-fit: cover;
  }

  h1 {
    font-size: 2.25em; /* 36px converti en em */
    margin-bottom: 0;
    color: white;
    text-align: center; /* Centre le texte */
    font-weight: 700;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  h2 {
    font-size: 2.25em; /* 36px converti en em */
    margin-bottom: 0;
    color: white;
    text-align: center; /* Centre le texte */
    font-weight: 700;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .subtitle-section {
    font-size: 1em; /* 16px converti en em */
    margin-top: 2em;
    margin-bottom: 0;
  }

  .founder-subtitle {
    font-size: 0.8em;
  }
  ul {
    padding-left: 0;
  }
  li {
    list-style-type: none;
  }
}