@media (min-width: 769px) { 
  .news {
    padding: 1.25em; /* 20px converti en em */
    text-align: center;
    font-family: Raleway, sans-serif;
    margin-top: 6em;
  }

  .title-h2 {
    color: #fff; /* Forcer la couleur blanche */
    font-size: 2.375em; /* 38px converti en em */
    font-weight: bold;
    margin: 1.25em; /* 20px converti en em */
  }

  .cta-newsTitle {
    font-family: 'Raleway', sans-serif;
    font-size: 2.375em; /* 38px converti en em */
    font-weight: bold;
    margin-bottom: 1.25em; /* 20px converti en em */
    color: #fff;
    transition: color 0.3s ease;
  }

  .news-items {
    display: flex;
    justify-content: center;
    gap: 3em; /* 20px converti en em */
  }

  .news-card {
    width: 18.75em; /* 300px converti en em */
    border-radius: 0.625em; /* 10px converti en em */
    box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
    text-align: center;
    padding: 1.25em; /* 20px converti en em */
    background-color: #fff;
    opacity: 0;
    transform: translateY(1.25em); /* 20px converti en em */
    transition: opacity 2s ease;
    text-decoration: none; /* Assurez-vous que le lien n'a pas de soulignement */
    color: inherit; /* Hérite de la couleur du texte */
  }

  .news-card.visible {
    opacity: 1;
  }

  .image {
    width: 18.75em; /* 300px converti en em */
    height: 10.9375em; /* 175px converti en em */
    border-radius: 0.625em; /* 10px converti en em */
    box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
  }

  .news-card h3 {
    font-size: 1.2em;
    font-weight: 600;
  }

  .top-news-meta {
    display: center;
    justify-content: space-between;
    margin: 0.625em; /* 10px converti en em */
    color: #888;
  }

  .NewsButtonContainer {
    text-align: center;
    margin-top: 10vh; /* 80px converti en em */
  }

  .NewsSecondaryButton {
    background-color: white;
    color: #243eff;
    border: none;
    padding: 0.9375em 1.875em; /* 15px 30px converti en em */
    border-radius: 3.125em; /* 50px converti en em */
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0;
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.5s ease;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .NewsSecondaryButton.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .NewsSecondaryButton:hover {
    background-color: #f5f5f5;
    transform: scale(1.05); /* Zoom au survol */
  }
}

/* Media queries pour rendre la section responsive */
@media (max-width: 768px) {
  .news {
    padding: 1.25em; /* 20px converti en em */
    text-align: center;
    font-family: Raleway, sans-serif;
  }
  
  .cta-newsTitle {
    font-family: 'Raleway', sans-serif;
    font-size: 1.75em; /* 38px converti en em */
    font-weight: bold;
    margin-bottom: 1.25em; /* 20px converti en em */
    color: #f5f5f5;
  }
  
  .news-items {
    display: flex;
    flex-direction: column; /* Change la disposition en colonne pour les petits écrans */
    justify-content: center;
    align-items: center; /* Centre les éléments horizontalement */
    gap: 1.875em; /* 30px converti en em */
    flex-wrap: wrap; /* Permettre aux éléments de passer à la ligne */
    margin-left: -2vh;
    margin-right: 3vh;
  }
  
  .news-card {
    width: 100%; /* Ajuste la largeur des cartes pour les petits écrans */
    max-width: 25em; /* 400px converti en em */
    min-height: 16.25em; /* 260px converti en em */
    border-radius: 0.625em; /* 10px converti en em */
    box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
    text-align: center;
    padding: 1.25em; /* 20px converti en em */
    background-color: #fff;
    text-decoration: none; /* Assurez-vous que le lien n'a pas de soulignement */
    color: inherit; /* Hérite de la couleur du texte */
    margin: 0 auto; /* Centre les cartes horizontalement */
  }
  
  .news-card.visible {
    opacity: 1;
  }

  .NewsButtonContainer {
    margin-top: 4vh; /* 80px converti en em */
  }

  .NewsSecondaryButton {
    background-color: white;
    color: #243eff;
    border: none;
    padding: 0.625em 1.25em; /* 15px 30px converti en em */
    border-radius: 3.125em; /* 50px converti en em */
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0;
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.5s ease;
    opacity: 0;
    transform: translateY(1.25em);
  }
  
  .NewsSecondaryButton.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .NewsSecondaryButton:hover {
    background-color: #f5f5f5;
    transform: scale(1.05); /* Zoom au survol */
  }
  
  .image {
    width: 100%; /* Ajuste la largeur de l'image pour qu'elle soit responsive */
    height: auto; /* Maintient le ratio de l'image */
    border-radius: 0.625em; /* 10px converti en em */
    box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.2); /* 4px 10px converti en em */
  }
  
  .news-card h3 {
    font-size: 1em;
    font-weight: 600;
  }

  .top-news-meta {
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
    margin: 0.625em; /* 10px converti en em */
    color: #888;
  }
}