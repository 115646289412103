/* ArticleCard.css */
.article-card {
  display: flex;
  flex-direction: column; /* Change la direction en colonne pour les petits écrans */
  border: 1px solid #ddd;
  border-radius: 0.25em; /* 4px converti en em */
  overflow: hidden;
  margin: 0.625em; /* 10px converti en em */
  text-decoration: none;
  color: inherit;
  background-color: #fff; /* Couleur de fond pour chaque carte */
  margin-top: 1.25em; /* 20px converti en em */
  padding: 1.25em; /* 20px converti en em */
  max-width: 100%; /* Largeur maximale de la carte */
  max-height: none; /* Supprime la hauteur maximale */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
}

.article-image {
  width: 100%; /* L'image prend toute la largeur */
  height: auto;
  object-fit: cover;
  border-radius: 0.25em; /* 4px converti en em */
  margin-right: 0; /* Supprime la marge droite */
  margin-bottom: 1.25em; /* 20px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.2); /* 4px 10px converti en em */

}

.article-content {
  display: flex;
  flex-direction: column;
  padding: 0.625em; /* 10px converti en em */
  flex: 1;
}

.article-title {
  margin: 0;
  font-size: 1.2em; /* Réduit la taille de la police pour les petits écrans */
  text-align: center;
  color: #243EFF; /* Couleur du titre de l'article */
  margin-bottom: 0.625em; /* 10px converti en em */
}

.article-subtitle {
  margin: 0.3125em 0; /* 5px converti en em */
  font-size: 1em; /* Réduit la taille de la police pour les petits écrans */
  color: black;
  text-align: justify; /* Justifie le texte du sous-titre */
}

.article-meta {
  margin: 0.3125em 0; /* 5px converti en em */
  color: #888;
  display: flex;
  justify-content: center; /* Centre le contenu horizontalement */
  margin-bottom: 0.625em; /* 10px converti en em */
}

.article-meta span {
  margin: 0 0.3125em; /* 5px converti en em */
}

.article-description {
  margin: 0.625em 0 0; /* 10px converti en em */
  color: #555;
  text-align: justify; /* Justifie le texte de la description */
}

/* Media queries pour les écrans plus larges */
@media (min-width: 600px) {
  .article-card {
    flex-direction: row; /* Change la direction en ligne pour les écrans plus larges */
    max-width: 75em; /* 1200px converti en em */
    max-height: 12.5em; /* 200px converti en em */
  }

  .article-image {
    width: 20.3125em; /* 325px converti en em */
    margin-right: 1.875em; /* 30px converti en em */
    margin-bottom: 0; /* Supprime la marge en bas */
  }

  .article-title {
    font-size: 1.5em; /* Taille de la police pour les écrans plus larges */
  }

  .article-subtitle {
    font-size: 1.2em; /* Taille de la police pour les écrans plus larges */
  }
}