@tailwind components;
@tailwind utilities;

.NewsCarousel-page{
    padding: 0;
    height: 90%;
}

.indicators-container {
    left: 46%;
  }
  
  @media (max-width: 768px) {
    .indicators-container {
      left: 30%;
    }
  }