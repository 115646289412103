/* Styles de base pour le composant Counter */
.counter {
  font-size: 5em; /* 80px converti en em */
  font-weight: bold;
  color: white; /* Assurez-vous que la couleur du texte est visible */
}

/* Styles pour la section des statistiques */
.stats {
  display: flex;
  flex-direction: column; /* Ajouté pour afficher les éléments en colonne */
  align-items: center; /* Centrer les éléments horizontalement */
  padding: 1.25em; /* 20px converti en em */
  margin-top: 5em;
  margin-bottom: 10em;
}

.stats-académie {
  display: flex;
  justify-content: space-around;
  padding: 1.25em; /* 20px converti en em */
  margin-bottom: 10em;
}

.stats-title {
  text-align: center;
  font-size: 2.35em; /* Ajustez la taille de la police selon vos besoins */
  margin-bottom: 1em; /* Ajustez la marge selon vos besoins */
}

.stats-items {
  display: flex; /* Affiche les éléments en ligne */
  justify-content: space-around; /* Espace les éléments horizontalement */
  width: 100%; /* Assure que les éléments prennent toute la largeur disponible */
}

.statItem {
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-bottom: 1em; /* Ajouté pour espacer les éléments verticalement */
}

.statItem.visible {
  opacity: 1;
}

.statItem p {
  margin: 0.625em 0 0; /* 10px converti en em */
  color: white;
  font-size: 1.1875em; /* 19px converti en em */
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.statItem.visible p {
  opacity: 1;
}

/* Media queries pour rendre la section responsive */
@media (max-width: 768px) {
  .counter {
    font-size: 2.875em; /* 46px converti en em */
  }

  .stats {
    flex-direction: column; /* Change la disposition en colonne pour les petits écrans */
    align-items: center; /* Centre les éléments pour les petits écrans */
    margin-top: 10vh; /* 40px converti en em */
    margin-bottom: 10vh;
    gap: 1.5em; /* 20px converti en em */
  }

  .stats-title{
    font-size: 1.75em;
    margin-bottom: 6vh;
  }

  .stats-items {
    flex-direction: column; /* Affiche les éléments en colonne sur les appareils mobiles */
    align-items: center; /* Centre les éléments horizontalement */
    gap: 1.75em;
  }

  .stats-académie {
    flex-direction: column; /* Change la disposition en colonne pour les petits écrans */
    align-items: center; /* Centre les éléments pour les petits écrans */
    margin-top: 0; /* 40px converti en em */
    margin-bottom: 6em;
    gap: 1.75em; /* 20px converti en em */
  }

  .statItem {
    margin-bottom: 1.25em; /* 20px converti en em */
  }

  .statItem p {
    font-size: 0.9375em; /* 15px converti en em */
  }

  .error {
    font-size: 1.125em; /* 18px converti en em */
  }
}