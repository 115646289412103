/* SearchDropdown.css */
.result-background {
  background-image: url('/src/assets/Wallpaper.png'); /* Chemin vers ton image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Image fixe pendant le scroll */
  padding: 1.25em; /* 20px converti en em */
  min-height: 100vh; /* Assurez-vous que le conteneur prend toute la hauteur de la fenêtre */
  margin-top: 0;
  margin-bottom: 0;
}

.result-search-results {
  margin-top: 5em; /* 80px converti en em */
  margin-bottom: 1.25em; /* 20px converti en em */
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.search-result-item {
  margin-bottom: 1.25em; /* 20px converti en em */
  width: 100%;
  padding: 0.9375em; /* 15px converti en em */
  border-radius: 0.5em; /* 8px converti en em */
  background-color: #fff; /* Fond blanc pour les résultats */
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.1); /* 4px 12px converti en em */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition pour les effets de survol */
}

.search-result-item:hover {
  transform: translateY(-0.3125em); /* -5px converti en em */
  box-shadow: 0 0.375em 1em rgba(0, 0, 0, 0.15); /* 6px 16px converti en em */
}

.search-result-item h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #243EFF; /* Couleur du texte */
}

.search-result-item p {
  margin: 0.625em 0 0; /* 10px converti en em */
  color: #555; /* Couleur du texte */
}

/* Styles pour la pagination */
.pagination {
  display: flex;
  justify-content: center; /* Centre les boutons de pagination horizontalement */
  align-items: center; /* Centre les boutons de pagination verticalement */
  gap: 0.625em; /* 10px converti en em */
  margin-top: 1.25em; /* 20px converti en em */
}

.page-button {
  padding: 0.625em 0.9375em; /* 10px 15px converti en em */
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-button:hover {
  background-color: #f0f0f0;
}

.page-button.active {
  background-color: #243EFF;
  color: #fff;
  border-color: #243EFF;
}

/* Media queries pour rendre le design responsive */
@media (max-width: 768px) {
  .result-background {
    padding: 2.25em; /* Réduire le padding sur les petits écrans */
  }

  .result-search-results {
    margin-top: 3em; /* Réduire la marge supérieure sur les petits écrans */
    margin-bottom: 1em; /* Réduire la marge inférieure sur les petits écrans */
  }

  .search-result-item {
    padding: 0.75em; /* Réduire le padding sur les petits écrans */
  }

  .search-result-item h3 {
    font-size: 1.25rem; /* Réduire la taille de la police sur les petits écrans */
  }

  .pagination {
    gap: 0.5em; /* Réduire l'espacement entre les boutons de pagination sur les petits écrans */
    margin-top: 1em; /* Réduire la marge supérieure sur les petits écrans */
  }

  .page-button {
    padding: 0.5em 0.75em; /* Réduire le padding sur les petits écrans */
  }
}