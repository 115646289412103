body {
  margin: 0;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New',
    monospace;
}

.footer {
  background-color: #282c34;
  color: white;
  padding: 1.25em; /* 20px converti en em */
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.25em; /* 20px converti en em */
  gap: 10em; /* 20px converti en em */
}

.footer-content div {
  margin: 0.625em; /* 10px converti en em */
}

.footer h4 {
  font-size: 1.1em;
  margin-bottom: 0.625em; /* 10px converti en em */
}

.social-link, .site-link, .legal-link {
  color: #f5f5f5;
  text-decoration: none;
  font-size: 0.875em;
}

.social-link:hover, .site-link:hover, .legal-link:hover {
  text-decoration: underline;
}

.newsletter-content {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 10em; /* Réduit la largeur maximale du conteneur */
  font-size: 0.875em;
}

.newsletter-subtitle {
  margin-bottom: 1.875em; /* 30px converti en em */
}

.subscribe-button {
  background-color: #243EFF;
  color: white;
  padding: 0.625em 1.25em; /* 10px 20px converti en em */
  border: none;
  border-radius: 3.125em; /* 50px converti en em */
  cursor: pointer;
  text-decoration: none;
  margin-top: 2.5em; /* 40px converti en em */
}

.footer-divider {
  border: 1px solid #444;
  margin: 1.25em 0; /* 20px converti en em */
}

.footer-legal {
  font-size: 0.9em;
}

.footer-legal p {
  margin: 0.3125em 0; /* 5px converti en em */
}

.adrien_moncet {
  color: inherit;
}

.svg-social-icon {
  width: 1.75em; /* 28px converti en em */
  height: auto;
  vertical-align: middle;
  margin-top: 0.625em; /* 10px converti en em */
}

.footer-logo {
  width: 10%;
  height: auto;
  margin-top: 1.25em; /* 20px converti en em */
}

@media (max-width: 768px) {
  .footer-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Grille avec deux colonnes */
    gap: 1.25em; /* 20px converti en em */
    align-items: start; /* Aligne les éléments au début de la grille */
  }
  
  .footer-logo {
    width: 30%;
  }
}