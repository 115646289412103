/* Navbar Container */
.navbar {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
  padding: 0.625em; /* 10px converti en em */
  background: linear-gradient(90deg, #d3d3d3, #ffffff);
  position: fixed;
  top: 0;
  height: 10hv;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box; /* Inclure le padding dans la largeur totale */
}

.main-content.no-padding {
  padding-top: 0; /* Supprime le padding-top pour les pages d'article */
}

/* Left Section (Logo and Navigation Links) */
.navbar__left-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.625em; /* 10px converti en em */
}

.navbar__logo-image {
  width: 8.125em; /* 130px converti en em */
  height: 2.5em; /* 40px converti en em */
  padding-left: 1.25em; /* 20px converti en em */
}

.navbar__nav-links {
  list-style: none;
  display: flex;
  gap: 0.625em; /* 10px converti en em */
  transition: transform 0.3s ease-in-out;
}

.navbar__nav-links--open {
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  padding: 1.25em; /* 20px converti en em */
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
}

.navbar__nav-links--open.open {
  transform: translateX(0); /* Animation d'ouverture */
}

.navbar__li-button {
  border: none;
  background: none;
  font-family: Arial, sans-serif;
  font-size: 1em; /* 16px converti en em */
  cursor: pointer;
  color: black;
  text-align: left;
}

/* Dropdown Menu Styling */
.navbar__dropdown-container {
  position: relative;
}

.navbar__dropdown-menu {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  transform: translateX(-25%);
  background-color: #ffffff;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1); /* 4px 8px converti en em */
  border-radius: 0.3125em; /* 5px converti en em */
  overflow: hidden;
  z-index: 1001;
  height: 3.125em; /* 50px converti en em */
  width: 110vw;
  cursor: pointer;
  list-style-type: none;
  padding: 0.3125em; /* 5px converti en em */
  margin: 0;
  display: flex; /* Utilisez flexbox pour aligner les éléments */
  justify-content: space-evenly; /* Espace entre les éléments */
  align-items: center; /* Aligne les éléments verticalement */
}

.navbar__dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5em 0.9375em; /* 8px 15px converti en em */
  font-size: 1.125em; /* 18px converti en em */
  color: #000;
  cursor: pointer;
}

.navbar__dropdown-item:hover {
  background-color: #f0f0f0;
}

.navbar__icon {
  width: 1.5em; /* 24px converti en em */
  height: auto;
  margin-right: 0.625em; /* 10px converti en em */
}

/* Right Section (User and Language) */
.navbar__right-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: 0.625em; /* 10px converti en em */
}

.navbar__signup-button {
  padding: 0.625em 1.25em; /* 10px 20px converti en em */
  background-color: #243EFF;
  color: white;
  border: none;
  border-radius: 3.125em; /* 50px converti en em */
  cursor: pointer;
  font-size: 1em; /* 16px converti en em */
}

.navbar__signup-button:hover {
  background-color: #1929bf;
}

.navbar__language-button {
  background: none;
  border: none;
  font-family: Arial, sans-serif;
  font-size: 1em; /* 16px converti en em */
  color: #000000;
}

.search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar__search-bar {
  padding: 0.5em; /* 8px converti en em */
  border-radius: 0.3125em; /* 5px converti en em */
  border: 1px solid #ccc;
  width: 9.375em; /* 150px converti en em */
  padding-right: 2.5em; /* 40px converti en em */
}

.search-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0.625em; /* 10px converti en em */
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.navbar__icon {
  margin-right: 0.3125em; /* 5px converti en em */
  font-size: 1em; /* 16px converti en em */
  cursor: pointer;
}

/* Arrow transition when dropdown is active */
.navbar__arrow {
  display: inline-block;
  margin-left: 0.3125em; /* 5px converti en em */
  transition: transform 0.3s ease;
  font-size: 0.5em; /* 8px converti en em */
}

.navbar__arrow--up {
  transform: rotate(180deg);
}

/* Overlay */
.navbar__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Burger Menu Icon */
.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 1.875em; /* 30px converti en em */
  height: 1.875em; /* 30px converti en em */
  cursor: pointer;
  z-index: 1001;
}

.burger-bar {
  width: 100%;
  height: 0.125em; /* 2px converti en em */
  background-color: #000;
  border-radius: 0.125em; /* 2px converti en em */
  transition: all 0.3s ease;
}

.burger-bar.open:nth-child(1) {
  transform: rotate(45deg) translate(0.5625em, 0.3125em); /* 9px 5px converti en em */
}

.burger-bar.open:nth-child(2) {
  opacity: 0;
}

.burger-bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(0.5em, -0.3125em); /* 8px -5px converti en em */
}

/* Burger Menu Container */
.burger-menu__container {
  position: fixed;
  top: 0;
  left: -100%; /* Hors de l'écran par défaut */
  width: 50%;
  height: 100%;
  margin-left: 50%;
  background-color: rgb(255, 255, 255); /* Fond légèrement transparent */
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out; /* Animation fluide */
  margin-top: 7.5vh;
}

.burger-menu__container.open {
  transform: translateX(100%); /* Glisse sur l'écran */
}

.burger-menu__content {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: flex-start;
}

.burger-menu__content li {
  margin: 1.25em 0; /* 20px converti en em */
}

.burger-menu__content li a {
  color: black;
  font-size: 1.125em; /* 18px converti en em */
  text-decoration: none;
  transition: color 0.3s ease;
  padding-left: 0.625em; /* 10px converti en em */
}

.burger-menu__content li a:hover {
  color: #00bcd4;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
    padding: 0.625em 0.9375em; /* 10px 15px converti en em */
    box-sizing: border-box;
    height: 7.5vh;
  }
  .navbar__signup-button {
    margin-right: -1em; /* Pousse le bouton d'inscription à droite */
  }

  .navbar__nav-links {
    display: none;
  }
  
  .burger-menu__dropdown {
    display: none;
  }

  .burger-menu {
    display: flex;
  }

  .navbar__search-bar {
    display: none;
  }

  .navbar__language-button {
    display: none;
  }

  .navbar__icon {
    width: 12%;
  }

  .burger-menu__dropdown-button {
    background: none;
    border: none;
    color: black;
    font-size: 1.125em; /* 18px converti en em */
    cursor: pointer;
    margin: 0.625em 0; /* 10px converti en em */
    display: flex;
    align-items: center;
    padding-left: 0.625em; /* 10px converti en em */
  }

  .burger-menu__dropdown {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 0.625em; /* 10px converti en em */
  }

  .burger-menu__dropdown li {
    margin: 0.625em 0; /* 10px converti en em */
    font-size: 0.875em; /* 14px converti en em */
    padding-left: 1.25em; /* 20px converti en em */
  }

  .burger-menu__dropdown li img {
    margin-right: 0.625em; /* 10px converti en em */
  }

  .burger-menu__search-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.25em; /* 20px converti en em */
    margin-left: 0.625em; /* 10px converti en em */
  }

  .burger-menu__search-bar {
    padding-top: 0.3125em; /* 5px converti en em */
    border-radius: 0.3125em; /* 5px converti en em */
    border: 1px solid #ccc;
    width: 93%; /* 150px converti en em */
  }

  .burger-menu__search-bar:focus {
    border-color: black; /* Change la couleur de la bordure en bleu */
    outline: none; /* Supprime l'effet de focus par défaut */
  }

  .burger-search-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0.625em; /* 5px converti en em */
    top: 50%;
    transform: translateY(-50%);
    color: #888;
  }

  .search-icon-button {
    display: none;
  }
}

@media (min-width: 769px) {
  .navbar__search-icon {
    display: none;
  }

  .navbar__search-bar {
    display: block;
  }
}