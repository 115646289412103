.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 2vw;
  box-sizing: border-box;
  background: url('../assets/Wallpaper.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 24.375em; /* 390vh converti en em */
}

.crypto-header {
  text-align: center;
  margin-bottom: 4vw;
  margin-top: 12vh;
}

.crypto-header h1 {
  font-size: 2.5rem;
  margin-bottom: 1vw;
}

.crypto-header p {
  font-size: 1.2rem;
  color: #fff;
}

.crypto-container {
  display: grid;
  /* Agrandir le bloc de gauche par rapport au bloc de droite */
  grid-template-columns: 1fr 1fr;
  /* Augmenter la hauteur de la container */
  min-height: 100vh;
  gap: 2vw;
  width: 100%;
  padding-left: 12vw;
  padding-right: 12vw;
  box-sizing: border-box;
}

/* Le bloc de gauche s’étend sur deux rangées */
.left-block {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.right-block {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2vw;
}

.big-news-card-container, .small-news-card-container {
  background-color: white;
  border-radius: 1vw;
  padding: 1vw;
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
}

.small-news-card-container {
  max-width: 14.1em;
  height: 19.5em;
}

/* Conteneur pour les images afin de contrôler le zoom */
.image-container {
  overflow: hidden; /* Empêche le débordement de l'image lors du zoom */
  border-radius: 0.8vw; /* Correspond aux bordures des images */
  position: relative; /* Ajouté pour positionner les éléments enfants */
}

/* Carte pour l’article principal */
.big-news-card {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 0.625em; /* 10px converti en em */
  text-align: center;
  padding: 0; /* Suppression du padding pour éviter le débordement */
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Empêche le débordement des images */
  transition: box-shadow 0.3s ease;
}

.big-news-card .image-container {
  width: 100%;
  height: 29.4em; /* Hauteur fixe pour uniformité */
  overflow: hidden; /* Empêche le débordement lors du zoom */
  border-radius: 0.8vw; /* Correspond aux bordures des images */
}

.big-news-card .image {
  width: 100%;
  height: 100%; /* Prend toute la hauteur du conteneur */
  object-fit: cover;
  transition: transform 0.3s ease; /* Ajout de la transition pour l'effet de zoom */
  display: block; /* Assure que l'image est un élément bloc */
}

.big-news-card .image:hover {
  transform: scale(1.05); /* Effet de zoom au survol */
}

.big-news-card .newsContent {
  padding: 1em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Carte pour les petits articles */
.small-news-card {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 0.625em; /* 10px converti en em */
  text-align: center;
  padding: 0; /* Suppression du padding pour éviter le débordement */
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Empêche le débordement des images */
  transition: box-shadow 0.3s ease;
}

.small-news-card .image-container {
  width: 100%;
  height: 18vw; /* Hauteur fixe pour les petites cartes */
  overflow: hidden; /* Empêche le débordement lors du zoom */
  object-fit: cover;
  border-radius: 0.8vw; /* Correspond aux bordures des images */
}

.small-news-card .image {
  width: 100%;
  height: 100%; /* Prend toute la hauteur du conteneur */
  object-fit: cover;
  transition: transform 0.3s ease; /* Ajout de la transition pour l'effet de zoom */
  display: block; /* Assure que l'image est un élément bloc */
}

.small-news-card .image:hover {
  transform: scale(1.05); /* Effet de zoom au survol */
}

.small-news-card .newsContent {
  padding: 0.5em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.crypto-container .small-news-card {
  height: 100%; /* Assurer que toutes les petites cartes ont la même hauteur */
}

.crypto-newsMeta {
  margin-top: 0px;
  font-size: 0.8em;
  color: #666;
}

.crypto-newsTitle {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1em;
  color: black;
}

.crypto-newsExcerpt {
  font-size: 0.9em;
  color: black;
}

.justify-text {
  text-align: justify;
}

.additional-news-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-left: 12vw;
  padding-right: 12vw;
  gap: 2vw;
  margin-top: 2vw;
}

.small-news-card {
  flex: 1;
}

.see-more-button-container {
  text-align: center;
  margin-top: 4vw;
}

.see-more-button {
  background-color: white;
  color: #243eff;
  border: none;
  padding: 0.9375em 1.875em; /* 15px 30px converti en em */
  border-radius: 3.125em; /* 50px converti en em */
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6vw;
  transition: background-color 0.3s ease;
  transform: translateY(1.25em);
}

.see-more-button:hover {
  background-color: #b5b5b5;
}