/* Académie2.css */
.page {
  position: relative;
  background: url('../assets/Wallpaper.png') no-repeat center center/cover;
  background-attachment: fixed; /* Le background reste fixe au scroll */
  text-align: center;
  min-height: 100vh; /* S'assure que ça couvre au moins la taille de l'écran */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
  min-height: 100vh; /* Ajoute cette propriété pour que le contenu s'étende sur toute la page */
  padding-bottom: 3.125em; /* 50px converti en em */
  margin-left: 14vw;
  margin-right: 14vw;
}

.header {
  text-align: center;
}

.title {
  font-size: 2.5em;
  font-weight: 700;
  color: #243EFF;
}

.subtitle {
  font-size: 1.2em;
  color: white;
  margin-bottom: 1.875em; /* 30px converti en em */
}

.primaryButton {
  background-color: #243EFF;
  color: white;
  padding: 0.9375em 1.875em; /* 15px 30px converti en em */
  border: none;
  border-radius: 3.125em; /* 50px converti en em */
  font-size: 1.2em;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.primaryButton:hover {
  background-color: #1a2ecc;
  transform: scale(1.05);
}

.academySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25em; /* 20px converti en em */
}

.Académie-sectionTitle {
  font-size: 2.2em;
  font-weight: 700;
  color: white;
}

.ErrorSectionTitle {
  font-size: 2.2em;
  font-weight: 700;
  margin-bottom: 2.75em; /* 60px converti en em */
  color: white;
}

.reviewsContainer {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Assure que les éléments enfants s'étirent pour avoir la même hauteur */
  flex-wrap: wrap;
  gap: 1.25em; /* 20px converti en em */
}

.review {
  flex: 1 1 30%; /* Chaque bloc prend 30% de la largeur et peut s'étendre */
  max-width: 18.75em; /* 300px converti en em */
  min-width: 15.625em; /* 250px converti en em */
  background-color: #f9f9f9;
  padding: 1.25em; /* 20px converti en em */
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1); /* 0 4px 8px converti en em */
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-left: 3em; /* 60px converti en em */
  justify-content: space-between; /* Assure que le contenu est bien réparti verticalement */
}

.reviewText {
  font-size: 1em; /* 16px converti en em */
  margin-bottom: 0.625em; /* 10px converti en em */
  flex-grow: 1; /* Permet au texte de prendre l'espace restant */
}

.reviewerName {
  font-size: 0.875em; /* 14px converti en em */
  font-style: italic;
  color: white;
}

.errorsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25em; /* 20px converti en em */
  margin-bottom: 3.75em; /* 60px converti en em */
}

.errorsContainer {
  display: flex;
  flex-direction: column;
  gap: 2em; /* Espace entre les blocs */
  max-width: 70vw; /* Limite la largeur maximale de la section */
}

.errorItem {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.25em; /* 20px converti en em */
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5); /* 0 4px 8px converti en em */
  margin-bottom: 1.25em; /* 20px converti en em */
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.errorItem.visible {
  opacity: 1;
  transform: translateX(0);
}

.errorItem.error2 {
  transform: translateX(50px);
}

.errorItem.error2.visible {
  transform: translateX(0);
}

.errorImage {
  flex: 0 0 auto;
  margin-right: 1.25em; /* 20px converti en em */
  width: 30%; /* 100px converti en em */
  height: auto; /* 100px converti en em */
}

.errorContent {
  flex: 1 1 auto;
  text-align: left;
}

.errorTitle {
  font-size: 1.5em;
  margin-bottom: 0.625em; /* 10px converti en em */
  color: #243EFF;
  text-align: center;
}

.errorText {
  font-size: 1em;
  margin-bottom: 0.625em; /* 10px converti en em */
  text-align: center;
}

.questionSection {
  text-align: center;
  margin-bottom: 3.75em; /* 60px converti en em */
  margin-left: 10em;
  margin-right: 10em;
}

.questionTitle {
  font-size: 2.2em;
  font-weight: 700;
  margin-bottom: 1.25em; /* 20px converti en em */
}

.questionText {
  font-size: 1.2em;
  margin-bottom: 2.5em; /* 40px converti en em */
  color: white;
}

.Académie-secondaryButton {
  background-color: #ffffff;
  color: #243EFF;
  padding: 0.9375em 1.875em; /* 15px 30px converti en em */
  border: none;
  border-radius: 3.125em; /* 50px converti en em */
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.5s ease, transform 0.5s ease;
  margin-bottom: 1.25em; /* 20px converti en em */
  opacity: 0;
  transform: translateY(20px);
}

.Académie-secondaryButton.visible {
  opacity: 1;
  transform: translateY(0);
}

.Académie-secondaryButton:hover {
  background-color: #f5f5f5;
  transform: scale(1.05);
}

.socialProof {
  font-size: 1em;
  color: white;
}

.keyFiguresSection {
  text-align: center;
  margin-top: 7.5em; /* 120px converti en em */
}

.keyFigures-sectionTitle {
  font-size: 2.2em;
  font-weight: 700;
  margin-bottom: 1.875em; /* 30px converti en em */
  color: white;
}

.keyFigureItem {
  font-size: 1.25em;
  margin-bottom: 0.625em; /* 10px converti en em */
  color: white;
}

.platformSection {
  margin-top: 6em; /* 30px converti en em */
  margin-bottom: 6em;
  padding: 1.75em 1.25em; /* 60px 20px converti en em */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
  opacity: 0;
  transform: translateY(1.25em);
  transition: opacity 0.5s ease, transform 0.5s ease;
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
}

.platformSection.visible {
  opacity: 1;
  transform: translateY(0);
}

.platformContainer {
  max-width: 50em; /* 800px converti en em */
  margin: 0 auto;
}

.platformTitle {
  font-family: 'Raleway', sans-serif;
  font-size: 2.375em; /* 38px converti en em */
  font-weight: bold;
  margin-bottom: 1.25em; /* 20px converti en em */
  color: #243EFF;
  transition: color 0.3s ease;
}

.platformSubtitle {
  font-family: 'Raleway', sans-serif;
  font-size: 1.0625em; /* 17px converti en em */
  margin-bottom: 1.875em; /* 30px converti en em */
  color: black;
  transition: color 0.3s ease;
}

.platformImageContainer {
  margin-left: 4vw;
  margin-right: 13vw;
}

.platformImage {
  width: 170%; /* Assure que l'image prend toute la largeur du conteneur */
  height: auto;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.2); /* 4px 10px converti en em */
}

.annotations {
  max-width: 25em; /* 400px converti en em */
  text-align: justify;
  color: black;
  margin-top: 8.75em; /* 140px converti en em */
  font-size: 1.25em;
}

.advantages-container {
  display: flex;
  justify-content: space-around;
  gap: 3em; /* 20px converti en em */
  margin-top: 1.25em; /* 20px converti en em */
  margin-bottom: 2em; /* 20px converti en em */ 
}

.advantage-card {
  background-color: #fff;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5); /* 0 4px 8px converti en em */
  padding: 1.25em; /* 20px converti en em */
  text-align: center;
  width: 18.75em; /* 300px converti en em */
  margin-bottom: 1.25em; /* 20px converti en em */
  opacity: 0;
  transition: opacity 1s ease;
}

.advantage-card.visible {
  opacity: 1;
}

.advantage-image {
  width: 70%;
  height: auto;
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2); /* 0 4px 8px converti en em */
}

.advantage-card h3 {
  font-size: 1.5em;
  color: #243EFF;
  margin-bottom: 0.625em; /* 10px converti en em */
}

.advantage-card p {
  font-size: 1em;
  color: black;
}

/* Media queries pour rendre la page responsive */
@media (max-width: 768px) { /* 768px converti en em */
  .page {
    background: url('../assets/Wallpaper.png') no-repeat center center/cover;
    text-align: center;
    min-height: 100vh; /* S'assure que ça couvre au moins la taille de l'écran */
    box-sizing: border-box; /* Inclut le padding et la bordure dans la hauteur totale */
  }

  .content {
    padding: 1.25em; /* 20px converti en em */
    box-sizing: border-box; /* Inclut le padding et la bordure dans la hauteur totale */
    margin-left: 7vw;
    margin-right: 7vw;
  }

  .header {
    margin-bottom: 2em; /* 32px converti en em */
  }

  .title {
    font-size: 2em; /* 32px converti en em */
    margin-bottom: 1em; /* 16px converti en em */
  }

  .subtitle {
    font-size: 1em; /* 16px converti en em */
  }

  .primaryButton {
    padding: 0.75em 1.5em; /* 12px 24px converti en em */
    font-size: 1em; /* 16px converti en em */
  }

  .Académie-secondaryButton {
    padding: 0.75em 1.5em; /* 12px 24px converti en em */
    font-size: 1em; /* 16px converti en em */
  }

  .academySection {
    padding: 1em; /* 16px converti en em */
  }

  .Académie-sectionTitle {
    font-size: 1.75em; /* 28px converti en em */
    margin-top: 0 !important; /* Supprime la marge */
  }

  .ErrorSectionTitle {
    font-size: 1.75em; /* 28px converti en em */
    margin-bottom: 2.5em; /* 40px converti en em */
    margin-top: 0 !important; /* Supprime la marge */
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .reviewsContainer {
    gap: 1em; /* 16px converti en em */
  }

  .review {
    max-width: 15em; /* 240px converti en em */
    min-width: 12.5em; /* 200px converti en em */
    padding: 1em; /* 16px converti en em */
    border-radius: 0.5em; /* 8px converti en em */
    margin-left: 1.5em; /* 24px converti en em */
  }

  .reviewText {
    font-size: 0.875em; /* 14px converti en em */
    margin-bottom: 0.5em; /* 8px converti en em */
  }

  .reviewerName {
    font-size: 0.75em; /* 12px converti en em */
  }

  .errorsSection {
    padding: 0; /* 16px converti en em */
  }

  .errorsContainer {
    gap: 1.5em; /* 24px converti en em */
  }

  .errorItem {
    flex-direction: column; /* Aligne les éléments verticalement */
    padding: 1em; /* 16px converti en em */
    border-radius: 0.5em; /* 8px converti en em */
    margin-bottom: 1em; /* 16px converti en em */
    text-align: center; /* Centre le texte */
    min-height: 42vh;
  }

  .errorImage {
    margin-right: 0;
    margin-bottom: 1em; /* 16px converti en em */
    width: 100%; /* Prend toute la largeur */
  }

  .errorTitle {
    font-size: 1.25em; /* 20px converti en em */
    margin-bottom: 0.5em; /* 8px converti en em */
    margin-top: 0 !important; /* Supprime la marge */
    text-align: center;
  }

  .errorText {
    font-size: 0.875em; /* 14px converti en em */
    margin-bottom: 0.5em; /* 8px converti en em */
    text-align: center;
  }

  .questionSection {
    margin-bottom: 2.5em; /* 40px converti en em */
    margin-left: 2em; /* 32px converti en em */
    margin-right: 2em; /* 32px converti en em */
  }

  .questionTitle {
    font-size: 1.75em; /* 28px converti en em */
    margin-bottom: 1em; /* 16px converti en em */
    margin-top: 4em !important; /* Supprime la marge */
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .questionText {
    font-size: 1em; /* 16px converti en em */
  }

  .socialProof {
    font-size: 0.875em; /* 14px converti en em */
    margin-top: 5em;
  }

  .keyFiguresSection {
    margin-top: 5em; /* 80px converti en em */
  }

  .keyFigures-sectionTitle {
    font-size: 1.75em; /* 28px converti en em */
    margin-bottom: 1.25em; /* 20px converti en em */
    margin-top: 0 !important; /* Supprime la marge */
  }

  .keyFigureItem {
    font-size: 1em; /* 16px converti en em */
    margin-bottom: 0.5em; /* 8px converti en em */
  }

  .platformSection {
    flex-direction: column;
    text-align: center;
    margin-bottom: 8em;
  }

  .platformTitle {
    font-size: 1.75em; /* 28px converti en em */
    margin-top: 0;
  }

  .platformSubtitle {
    font-size: 0.9375em; /* 15px converti en em */
  }

  .platformContainer {
    align-items: center;
    max-width: 100%; /* Assure que le conteneur de l'image prend toute la largeur disponible */
  }

  .platformImage {
    margin-left: 0; /* Supprime la marge gauche pour les petits écrans */
    width: 120%;
  }

  .annotations {
    max-width: 19em; /* 320px converti en em */
    margin-top: 7em; /* 80px converti en em */
    margin-bottom: -4em;
    font-size: 1em;
  }

  .advantages-container {
    flex-direction: column;
    align-items: center;
    margin-top: 5em;
    gap: 1.25em; /* 20px converti en em */
    margin-bottom: 2em;
  }

  .advantage-card {
    width: 100%; /* Ajuste la largeur des cartes pour les petits écrans */
    margin-bottom: 1.25em; /* 20px converti en em */
  }

  .advantage-image {
    width: 100%; /* Ajuste la largeur de l'image pour qu'elle soit responsive */
    height: auto; /* Maintient le ratio de l'image */
  }

  .advantage-card h3 {
    font-size: 1.2em; /* Ajuste la taille de la police pour les petits écrans */
  }

  .advantage-card p {
    font-size: 0.9em; /* Ajuste la taille de la police pour les petits écrans */
  }
}