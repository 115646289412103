.ActuBackground {
  background: url('../assets/Wallpaper.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 24.375em; /* 390vh converti en em */
}

.ActuContainer {
  text-align: center;
  padding: 1.25em; /* 20px converti en em */
}

.header {
  padding: 3.75em 1.25em; /* 60px 20px converti en em */
  text-align: center;
  margin-bottom: 0;
  margin-top: 1.25em; /* 20px converti en em */
}

.header2 {
  padding: 3.75em 1.25em; /* 60px 20px converti en em */
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}

.title {
  font-size: 3.5em; /* 56px converti en em */
  font-weight: 700;
  color: #f5f5f5;
}

.newsletterTitle {
  font-size: 3.5em; /* 56px converti en em */
  font-weight: 700;
  color: #f5f5f5;
}

.subtitle {
  font-size: 1.1875em; /* 19px converti en em */
  color: #f5f5f5;
  margin-bottom: 1.875em; /* 30px converti en em */
}

.primaryButton {
  background-color: #243eff;
  color: white;
  padding: 0.9375em 1.875em; /* 15px 30px converti en em */
  border: none;
  border-radius: 3.125em; /* 50px converti en em */
  font-size: 1.2em;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.primaryButton:hover {
  background-color: #1d2ee7;
  transform: scale(1.05); /* Zoom au survol */
}

.guidesSection {
  padding: 3.75em 1.25em; /* 60px 20px converti en em */
  margin-top: -2.5em; /* -40px converti en em */
}

.sectionTitle {
  font-size: 2.375em; /* 38px converti en em */
  font-weight: 600;
  margin-bottom: 2.5em; /* 40px converti en em */
  text-align: center;
  color: #f5f5f5;
}

.guidesGrid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3em; /* 20px converti en em */
}

.guideCard {
  width: 18.75em; /* 300px converti en em */
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
  text-align: center;
  padding: 1.25em; /* 20px converti en em */
  background-color: #fff; /* Assurez-vous que le fond est blanc */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Ajout de la transition pour l'effet de survol */
  text-decoration: none;
  color: inherit;
}

.guideCardHover {
  transform: translateY(-0.625em); /* -10px converti en em */
  box-shadow: 0 0.5em 1.25em rgba(0, 0, 0, 0.5); /* 8px 20px converti en em */
}

.guideTitle {
  font-size: 1.1875em; /* 19px converti en em */
  font-weight: 600;
}

.latestNewsSection {
  padding: 3.75em 1.25em; /* 60px 20px converti en em */
  margin-top: -2.5em; /* -40px converti en em */
}

.newsGrid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3em; /* 20px converti en em */
}

.newsCard {
  width: 18.75em; /* 300px converti en em */
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
  text-align: center;
  padding: 1.25em; /* 20px converti en em */
  background-color: #fff; /* Assurez-vous que le fond est blanc */
}

.newsTitle {
  font-size: 1.1875em; /* 19px converti en em */
  font-weight: 600;
  text-decoration: none;
}

.newsLink {
  text-decoration: none;
  color: inherit;
}

.newsMeta {
  font-size: 0.875em; /* 14px converti en em */
  color: #888;
}

.ActuButtonContainer {
  text-align: center;
  margin-top: 6vh; /* 80px converti en em */
}

.ActuSecondaryButton {
  background-color: white;
  color: #243eff;
  border: none;
  padding: 0.9375em 1.875em; /* 15px 30px converti en em */
  border-radius: 3.125em; /* 50px converti en em */
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 0;
  transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.5s ease;
  opacity: 0;
  transform: translateY(1.25em);
}

.ActuSecondaryButton.visible {
  opacity: 1;
  transform: translateY(0);
}

.ActuSecondaryButton:hover {
  background-color: #f5f5f5;
  transform: scale(1.05); /* Zoom au survol */
}

.image {
  width: 18.75em; /* 300px converti en em */
  height: 10.9375em; /* 175px converti en em */
  border-radius: 0.625em; /* 10px converti en em */
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.2); /* 4px 10px converti en em */
}

.newsletterContainer {
  margin-top: 3.75em; /* 60px converti en em */
  text-align: center;
}

.subtitle2 {
  font-size: 1.1875em; /* 19px converti en em */
  color: #f5f5f5;
  margin-bottom: 2.5em; /* 40px converti en em */
}

/* Media Queries pour rendre le site responsive */
@media (max-width: 768px) {

  .ActuBackground {
    min-height: 30.625em; /* 490vh converti en em */
  }

  .container {
    text-align: center;
    margin: 0; /* Suppression de la marge */
  }

  .title {
    font-size: 2.25em; /* 36px converti en em */
    font-weight: bold;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .subtitle, .subtitle2 {
    font-size: 1em; /* 16px converti en em */
  }

  .primaryButton {
    padding: 0.625em 1.25em; /* 10px 20px converti en em */
    font-size: 1em;
  }

  .ActuSecondaryButton {
    padding: 0.625em 1.25em; /* 10px 20px converti en em */
    font-size: 1em;
    margin-left: 0;
  }

  .guideCard {
    width: 70%; /* Ajuste la largeur des cartes pour les petits écrans */
    max-width: 25em; /* 400px converti en em */
    min-height: 16.25em; /* 260px converti en em */
    border-radius: 0.625em; /* 10px converti en em */
    box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
    text-align: center;
    padding: 1.25em; /* 20px converti en em */
    background-color: #fff;
    text-decoration: none; /* Assurez-vous que le lien n'a pas de soulignement */
    color: inherit; /* Hérite de la couleur du texte */
  }

  .newsCard {
    width: 70%; /* Ajuste la largeur des cartes pour les petits écrans */
    max-width: 25em; /* 400px converti en em */
    min-height: 17.5em; /* 280px converti en em */
    border-radius: 0.625em; /* 10px converti en em */
    box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.5); /* 4px 10px converti en em */
    text-align: center;
    padding: 1.25em; /* 20px converti en em */
    background-color: #fff;
    text-decoration: none; /* Assurez-vous que le lien n'a pas de soulignement */
    color: inherit; /* Hérite de la couleur du texte */
  }

  .image {
    width: 100%;
    height: auto;
  }

  .guidesSection, .latestNewsSection {
    padding: 0;
    margin-top: 5em; /* 80px converti en em */
  }

  .guidesGrid, .newsGrid {
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }

  .header {
    padding: 2.5em 0.625em; /* 40px 10px converti en em */
    padding-bottom: 0;
  }

  .header2 {
    margin-top: -5vh;
  }

  .sectionTitle {
    font-size: 1.75em; /* 28px converti en em */
    margin-top: 5em; /* 80px converti en em */
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .newsTitle {
    font-size: 1em;
    font-weight: 600;
    text-decoration: none;
  }
  
  .newsLink {
    text-decoration: none;
    color: inherit;
  }
  
  .newsMeta {
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
    margin: 0.625em; /* 10px converti en em */
    color: #888;
  }

  .newsletterTitle {
    margin-top: 3.75em; /* 60px converti en em */
    font-size: 2.25em; /* 36px converti en em */
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  /* Désactiver l'animation sur mobile */
  .guideCardHover {
    transform: none;
    box-shadow: none;
  }
}