/* Home.css */
@media (min-width: 769px) {

  .page {
    position: relative;
    background: url('../assets/Wallpaper.png') no-repeat center center/cover;
    background-attachment: fixed; /* Le background reste fixe au scroll */
    text-align: center;
    min-height: 100vh; /* S'assure que ça couvre au moins la taille de l'écran */
  }

  .home-content {
    position: relative;
    margin-left: 13vw;
    margin-right: 13vw;
    padding-bottom: 3.125em; /* 50px converti en em */
  }
}

/* Media queries pour rendre la page responsive */
@media (max-width: 768px) {
  .page {
    background: url('../assets/Wallpaper.png') no-repeat center center/cover;
    text-align: center;
    box-sizing: border-box; /* Inclut le padding et la bordure dans la hauteur totale */
  }

  .home-content {
    margin-left: 7vw;
    margin-right: 7vw;
    padding: 1.25em; /* 20px converti en em */
  }
}